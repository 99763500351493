import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'common/redux/store';

import { navSideBarState as initialState } from './constants/navSideBarState';

export const navSideBarSlice = createSlice({
  name: 'navSideBar',
  initialState,
  reducers: {
    setNavSideBarOpen: (state, action) => {
      return {
        ...state,
        open: action.payload,
      };
    },
  },
});

export const { setNavSideBarOpen } = navSideBarSlice.actions;

export const selectNavSideBarState = (state: RootState) => state.navSideBar;

export default navSideBarSlice.reducer;
