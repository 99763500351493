import { ThemeProvider as EmoThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { CssBaseline } from '@mui/material';
import * as muiLocales from '@mui/material/locale';
import { Localization } from '@mui/material/locale';
import { ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as datePickersLocales from '@mui/x-date-pickers/locales';
import { ReactNode, useMemo } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { muiLocaleMap } from 'common/utils/muiLocaleMap';

import { GlobalStyles } from './globalStyles';
import { theme } from './theme';

type Props = {
  children?: ReactNode;
};

export const ThemeProvider = ({ children }: Props) => {
  const { i18n } = useTranslation();
  const muiLocale = muiLocaleMap[i18n.language];
  const muiCoreTranslates = (muiLocales as Record<string, Localization>)[muiLocale];
  const muiCoreDatePickersTranslates = (datePickersLocales as Record<string, Localization>)[muiLocale];

  const themeWithLocale = useMemo(
    () => createTheme(theme, muiCoreTranslates, muiCoreDatePickersTranslates),
    [muiCoreDatePickersTranslates, muiCoreTranslates],
  );

  return (
    <StyledEngineProvider injectFirst>
      <EmoThemeProvider theme={themeWithLocale}>
        <MUIThemeProvider theme={themeWithLocale}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </MUIThemeProvider>
      </EmoThemeProvider>
    </StyledEngineProvider>
  );
};
