export const register = (): void => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`).catch((error: unknown) => {
        console.error('Error during service worker registration:', error);
      });
    });
  }
};

export const unregister = (): void => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error: unknown) => {
        console.error('Error during service worker unregister', error);
      });
  }
};
