import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'common/redux/store';

const meetingSearchSlice = createSlice({
  name: 'meetingSearch',
  initialState: {
    searchText: '',
  },
  reducers: {
    setSearchText: (state, action) => {
      return {
        ...state,
        searchText: action.payload,
      };
    },
  },
});

export const { setSearchText } = meetingSearchSlice.actions;
export const selectMeetingSearch = (state: RootState) => state.meetingSearch;

export default meetingSearchSlice.reducer;
