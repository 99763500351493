import { ThemeOptions } from '@mui/material';

import './muiTypesOverrides';
import { palette } from './palette';
import { typography } from './typography';

export const theme: ThemeOptions = {
  palette: {
    mode: 'dark',
    ...palette,
  },
  typography,
  shape: { borderRadius: 16 },
  components: {
    MuiButton: { styleOverrides: { root: { textTransform: 'none' } } },
    MuiList: { styleOverrides: { root: { paddingTop: '13px', paddingBottom: '13px' } } },
    MuiTextField: {
      defaultProps: { size: 'small' },
    },
    MuiFormControl: {
      defaultProps: { size: 'small' },
    },
  },
};
