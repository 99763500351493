import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import authReducer from 'modules/auth/redux/authReducer';
import chatReducer from 'modules/private/chat/redux/chatReducer';
import bottomNavigationReducer from 'modules/private/layout/PrivateLayout/redux/bottomNavigationReducer';
import navSideBarReducer from 'modules/private/layout/PrivateLayout/redux/navSideBarReducer';
import meetingSearchReducer from 'modules/private/meetings/list/redux/meetingSearchReducer';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    auth: authReducer,
    meetingSearch: meetingSearchReducer,
    bottomNavigation: bottomNavigationReducer,
    navSideBar: navSideBarReducer,
    chat: chatReducer,
  },
});

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
