import auth from './auth/auth.json';
import login from './auth/login.json';
import passwordRecovery from './auth/passwordRecovery.json';
import registration from './auth/registration.json';
import common from './common.json';
import errors from './errors.json';
import privateChat from './private/chat.json';
import privateHome from './private/home.json';
import privateMeetings from './private/meetings.json';
import privateNavbar from './private/navbar.json';
import privateProfile from './private/profile.json';
import privateSubscriptions from './private/subscriptions.json';
import emailConfirmation from './profileFilling/emailConfirmation.json';
import profileFilling from './profileFilling/profile.json';

export default {
  common,
  errors,
  auth,
  login,
  registration,
  privateHome,
  privateMeetings,
  privateProfile,
  privateChat,
  profileFilling,
  emailConfirmation,
  passwordRecovery,
  privateNavbar,
  privateSubscriptions,
};
