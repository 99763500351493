import { SnackbarProvider } from 'notistack';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const AppSnackbar = ({ children }: Props) => (
  <SnackbarProvider
    maxSnack={3}
    hideIconVariant={false}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    autoHideDuration={3000}
  >
    {children}
  </SnackbarProvider>
);
