import { lightGreen } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  main: '#92E3A9',
  contrastText: '#000000',
};

const SECONDARY = {
  main: 'rgba(255,255,255,0.08)',
  contrastText: 'rgba(255,255,255,0.86)',
};

const TEAL = {
  lighter: '#33a095',
  light: '#33a095',
  main: '#00897b',
  dark: '#005f56',
  darker: '#005f56',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#1e88e5',
  main: '#2196f3',
  dark: '#0d47a1',
  darker: '#1976d2',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  main: '#FCA032',
  contrastText: 'rgba(255,255,255,0.86)',
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FF1744',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

export const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  teal: TEAL,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: {
    600: GREY[500],
  },
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: 'rgba(255, 255, 255, 0.86)',
    secondary: 'rgba(255, 255, 255, 0.58)',
  },
  background: {
    default: '#17181C',
    paper: '#2d2e32',
    neutral: '#4f5055',
    lightGrey: GREY[400],
  },
};
