import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IndexedDBHelper } from 'common/indexedDbHelper/IndexedDbHelper';
import { STORE_KEYS } from 'common/indexedDbHelper/constants';
import { RootState } from 'common/redux/store';
import { queryClient } from 'common/utils/queryClient';
import { SessionCurrentResponseType } from 'modules/private/requests/type';

import { authInitialState as initialState } from './constants/authState';
import { AuthUserResponseType, ProfileResponseType } from '../types';

const removeTokens = async () => {
  const db = await IndexedDBHelper.getInstance();
  await db.put({ key: STORE_KEYS.accessToken, value: null });
  await db.put({ key: STORE_KEYS.refreshToken, value: null });
};

export const login = createAsyncThunk('auth/login', async (payload: AuthUserResponseType, thunkAPI) => {
  const db = await IndexedDBHelper.getInstance();
  await db.put({ key: STORE_KEYS.accessToken, value: payload.accessToken || null });
  return payload;
});

export const sessionCurrent = createAsyncThunk('auth/sessionCurrent', async (payload: SessionCurrentResponseType) => {
  const db = await IndexedDBHelper.getInstance();
  const accessToken = await db.get(STORE_KEYS.accessToken);

  return { ...payload, accessToken };
});

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (payload: Partial<ProfileResponseType>) => payload,
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await removeTokens();
  queryClient.clear();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isAuth = true;
        state.user = action.payload;
      })
      .addCase(sessionCurrent.fulfilled, (state, action) => {
        state.isAuth = !!action.payload;
        state.user = action.payload;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        if (state.user && state.user.profile) {
          state.user.profile = { ...state.user.profile, ...action.payload };
          state.isAuth = true;
        }
      })
      .addCase(logout.fulfilled, () => {
        return {
          isAuth: false,
          user: null,
        };
      });
  },
});

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
