import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'common/redux/store';
import { ChatResponseType } from 'modules/private/chat/type';

type ChatState = {
  openChat: ChatResponseType | null;
};

const initialState: ChatState = {
  openChat: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setOpenChat: (state, action) => {
      return {
        ...state,
        openChat: action.payload,
      };
    },
  },
});

export const { setOpenChat } = chatSlice.actions;

export const selectChat = (state: RootState) => state.chat;

export default chatSlice.reducer;
