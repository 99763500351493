import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'common/redux/store';

import { bottomNavigationInitialState as initialState } from './constants/bottomNavigationState';

export const bottomNavigationSlice = createSlice({
  name: 'bottomNavigation',
  initialState,
  reducers: {
    hide: (state) => {
      return {
        ...state,
        isBottomNavigationVisible: false,
      };
    },
    show: (state) => {
      return {
        ...state,
        isBottomNavigationVisible: true,
      };
    },
  },
});

export const { hide, show } = bottomNavigationSlice.actions;

export const selectBottomNavigationState = (state: RootState) => state.bottomNavigation;

export default bottomNavigationSlice.reducer;
