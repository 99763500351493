import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;
export const Loading = () => (
  <LoadingWrapper>
    <CircularProgress />
  </LoadingWrapper>
);
