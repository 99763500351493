import { DB_NAME, STORE_NAME } from './constants';

const DB_VERSION = 1;

export class IndexedDBHelper {
  private static instance: IndexedDBHelper | null = null;

  private db: IDBDatabase | null = null;

  private isDBOpenPromise: Promise<void> | null = null;

  private constructor(private dbName = DB_NAME, private storeName = STORE_NAME) {}

  static async getInstance(dbName = DB_NAME, storeName = STORE_NAME): Promise<IndexedDBHelper> {
    if (!IndexedDBHelper.instance) {
      IndexedDBHelper.instance = new IndexedDBHelper(dbName, storeName);
      await IndexedDBHelper.instance.open();
    }

    return IndexedDBHelper.instance;
  }

  private open(): Promise<void> {
    if (this.isDBOpenPromise) {
      return this.isDBOpenPromise;
    }

    this.isDBOpenPromise = new Promise<void>((resolve, reject) => {
      const request = indexedDB.open(this.dbName, DB_VERSION);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(this.storeName, { keyPath: 'key' });
      };

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        this.db = db;
        resolve();
      };

      request.onerror = (event) => {
        const target = event.target as IDBOpenDBRequest;
        reject(target.error);
      };
    });

    return this.isDBOpenPromise;
  }

  async put(data: { key: string; value: any }): Promise<void> {
    await this.open();

    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        throw new Error('DB not opened');
      }

      const transaction = this.db.transaction(this.storeName, 'readwrite');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.put(data);

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async get(key: string): Promise<any> {
    await this.open();

    return new Promise<any>((resolve, reject) => {
      if (!this.db) {
        throw new Error('DB not opened');
      }

      const transaction = this.db.transaction(this.storeName, 'readonly');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.get(key);

      request.onsuccess = () => resolve(request.result?.value);
      request.onerror = () => reject(request.error);
    });
  }
}
