import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from 'routes';

import { AppSnackbar } from './common/components/AppSnackbar/AppSnackbar';
import i18n from './common/i18n/i18n';
import { store } from './common/redux/store';
import { ThemeProvider } from './common/theme';
import { queryClient } from './common/utils/queryClient';
import 'dayjs/locale/ru';
import './global.d';

console.info('app version', process.env.__VERSION__);

dayjs.locale(i18n.language);
export const register = (): void => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`).catch((error: unknown) => {
        console.error('Error during service worker registration:', error);
      });
    });
  }
};

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <AppSnackbar>
              <AppRoutes />
            </AppSnackbar>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </LocalizationProvider>
);

register();

export default App;
