import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { FlexBox } from 'common/components/FlexBox';
import { Loading } from 'common/components/Loading/Loading';

const AuthLayout = lazy(() => import('modules/auth/layout/AuthLayout'));
const AuthRoutes = lazy(() => import('modules/auth/routes'));
const PrivateRoutes = lazy(() => import('modules/private/routes'));
const ProfileFillingRoutes = lazy(() => import('modules/profile-filling/routes'));
const PrivateLayout = lazy(() => import('modules/private/layout/PrivateLayout/PrivateLayout'));
const PublicLanding = lazy(() => import('modules/public/PublicLanding'));
const PublicOffer = lazy(() => import('modules/public/documents/publicOffer/PublicOffer'));
const PrivacyPolicy = lazy(() => import('modules/public/documents/privacyPolicy/PrivacyPolicy'));

export const AppRoutes = () => (
  <Suspense
    fallback={
      <FlexBox height="100vh" alignItems="center" justifyContent="center" flex={1}>
        <Loading />
      </FlexBox>
    }
  >
    <Routes>
      <Route path="/" element={<PublicLanding />} />
      <Route path="/documents/public-offer" element={<PublicOffer />} />
      <Route path="/documents/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="/auth/*" element={<AuthRoutes />} />
      </Route>

      <Route path="/profile-filling">
        <Route path="/profile-filling/*" element={<ProfileFillingRoutes />} />
      </Route>

      <Route path="/private" element={<PrivateLayout />}>
        <Route path="/private/*" element={<PrivateRoutes />} />
      </Route>

      <Route path="/*" element={<>NotFound</>} />
    </Routes>
  </Suspense>
);
